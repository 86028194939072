import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  Link as RouterLink,
} from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Breadcrumbs,
  Link,
  Toolbar,
  Typography,
  Divider,
  Drawer,
  Button,
  IconButton,
  Grid,
  GridList,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  AppBar,
  Slide,
  Tab,
  Tabs,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Skeleton } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import localization from "../utils/localizations";
import { Header } from "../views/panels/Header";

import { SessionContext } from "../data/Session";

import { BaseAPI } from "../data/BaseAPI";

import { useProfile } from "../data/Profile";

import { WarrantyClaim, Customer, WarrantyTransferRequest } from "../types";

import WarrantyClaimCollection from "../views/collections/WarrantyClaimCollection";
import WarrantyClaimDetail from "../views/singles/WarrantyClaimDetail";
import WarrantyClaimForm from "../views/forms/WarrantyClaimForm";
import CustomerCollection from "../views/collections/CustomerCollection";
import CustomerDetail from "../views/singles/CustomerDetail";
import WarrantyTransferCollection from "../views/collections/WarrantyTransferCollection";
import WarrantyTransferRequestDetail from "../views/singles/WarrantyTransferRequestDetail";
import DateTimeView from "../views/singles/DateTimeView";
import CustomerForm from "../views/forms/CustomerForm";
import WarrantyTransferRequestForm from "../views/forms/WarrantyTransferRequestForm";

import { ClaimsReport, RegistrationsReport, FRCReport } from "../views/reports";

import { WarrantySettings } from "../views/settings";

const appConfig = (window as any).APP_CONFIG;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 12,
    paddingRight: 12,
    position: "relative",
  },
  pageContainer: {
    padding: 10,
  },
  marginBottom: {
    marginBottom: 10,
  },
  logoContainer: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 50,
  },
  appBar: {
    position: "relative",
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    marginBottom: 10,
    padding: 10,
  },
  tabNavigation: {
    marginBottom: 12,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function WarrantyClaimDetailPanel(props: {
  onUpdated: (warrantyClaim: WarrantyClaim) => void;
  onDeleted: (warrantyClaim: WarrantyClaim) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [warrantyClaim, setWarrantyClaim] = useState(
    undefined as WarrantyClaim | undefined,
  );
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { warrantyClaimId } = useParams() as any;
  const backUrl = `/warranty/claims/`;

  const loadWarrantyClaim = async (id: string) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`warranty-claims/${id}/`);
      if ((data as any).id) {
        setWarrantyClaim(data as WarrantyClaim);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadWarrantyClaim(warrantyClaimId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);
  }, [warrantyClaimId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-order-title"
        aria-describedby="selected-order-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.dialogTitle}>
                Claim #{warrantyClaim?.claim_number}
              </Typography>
              <Typography style={{ marginRight: 20 }}>
                {warrantyClaim?.status_text}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setNavigateBack(true);
                  }, 300);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ backgroundColor: "#f5f5f5" }}>
            {!!warrantyClaim && (
              <WarrantyClaimDetail
                claim={warrantyClaim}
                onUpdated={(warrantyClaim) => {
                  setWarrantyClaim(warrantyClaim);
                  props.onUpdated(warrantyClaim);
                }}
                onDeleted={(warrantyClaim) => {
                  props.onDeleted(warrantyClaim);
                  setNavigateBack(true);
                }}
              />
            )}
            {!warrantyClaim && (
              <div>
                <LoadingView />
              </div>
            )}
          </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}

function CustomerDetailPanel(props: {
  onUpdated: (customer: Customer) => void;
  onDeleted: (customer: Customer) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [customer, setCustomer] = useState(undefined as Customer | undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { customerId } = useParams() as any;
  const backUrl = `/warranty/customers/`;

  const loadCustomer = async (id: string) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`warranty-customers/${id}/?view=1`);
      if ((data as any).id) {
        setCustomer(data as Customer);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadCustomer(customerId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);
  }, [customerId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-order-title"
        aria-describedby="selected-order-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.dialogTitle}>
                {customer?.first_name} {customer?.last_name}
              </Typography>
              <Typography style={{ marginRight: 20 }}>
                {customer?.boat?.model}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setNavigateBack(true);
                  }, 300);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ backgroundColor: "#f5f5f5" }}>
            {!!customer && (
              <CustomerDetail
                customer={customer}
                onUpdated={(customer) => {
                  setCustomer(customer);
                  props.onUpdated(customer);
                }}
                onDeleted={(customer) => {
                  props.onDeleted(customer);
                  setNavigateBack(true);
                }}
              />
            )}
            {!customer && (
              <div>
                <LoadingView />
              </div>
            )}
          </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}

function WarrantyTransferDetailPanel(props: {
  onUpdated: (transfer: WarrantyTransferRequest) => void;
  onDeleted: (transfer: WarrantyTransferRequest) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const [transfer, setTransfer] = useState(
    undefined as WarrantyTransferRequest | undefined,
  );
  const [isLoading, setIsLoading] = useState(false);

  const { path, url } = useRouteMatch();
  const { transferId } = useParams() as any;
  const backUrl = `/warranty/warranty-transfers/`;

  const loadWarrantyTransferRequest = async (id: string) => {
    if (!id) return;
    if (isLoading) return;

    const api = new BaseAPI();
    setIsLoading(true);
    try {
      const data = await api.get(`warranty-transfer-requests/${id}/?view=1`);
      if ((data as any).id) {
        setTransfer(data as WarrantyTransferRequest);
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadWarrantyTransferRequest(transferId);

    setNavigateBack(false);
    setTimeout(() => {
      setOpen(true);
    }, 300);
  }, [transferId]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            setNavigateBack(true);
          }, 300);
        }}
        aria-labelledby="selected-transfer-title"
        aria-describedby="selected-transfer-description"
        fullScreen
        TransitionComponent={Transition}
      >
        <>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.dialogTitle}>
                {transfer?.first_name} {transfer?.last_name} ({transfer?.status}
                )
              </Typography>
              <Typography style={{ marginRight: 20 }}>
                {transfer?.original_customer?.boat?.model}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setNavigateBack(true);
                  }, 300);
                }}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ backgroundColor: "#f5f5f5" }}>
            {!!transfer && (
              <WarrantyTransferRequestDetail
                transfer={transfer}
                onUpdated={(transfer) => {
                  setTransfer(transfer);
                  props.onUpdated(transfer);
                }}
                onApproved={(transfer) => {
                  setTransfer(transfer);
                  props.onUpdated(transfer);
                }}
                onDeleted={(transfer) => {
                  props.onDeleted(transfer);
                  setNavigateBack(true);
                }}
              />
            )}
            {!transfer && (
              <div>
                <LoadingView />
              </div>
            )}
          </DialogContent>
        </>
      </Dialog>

      {navigateBack && <Redirect to={backUrl} />}
    </>
  );
}

function LoadingView() {
  return (
    <div style={{ position: "relative", padding: 10 }}>
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
      <Skeleton
        variant="rect"
        width={"100%"}
        height={20}
        style={{ marginBottom: 10 }}
      />
    </div>
  );
}

export default function WarrantyPage() {
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const matchClaims = useRouteMatch(`${appConfig.homepage}warranty/claims/`);
  const matchCustomer = useRouteMatch(
    `${appConfig.homepage}warranty/customers/`,
  );
  const matchTransfer = useRouteMatch(
    `${appConfig.homepage}warranty/warranty-transfers/`,
  );
  const matchClaimsReport = useRouteMatch(
    `${appConfig.homepage}warranty/reports/claim/`,
  );
  const matchRegistrationsReport = useRouteMatch(
    `${appConfig.homepage}warranty/reports/registration/`,
  );
  const matchFRCReport = useRouteMatch(
    `${appConfig.homepage}warranty/reports/frc/`,
  );
  const matchSettings = useRouteMatch(
    `${appConfig.homepage}warranty/settings/`,
  );
  const [profile, profileLoading, updateProfile, updateProfilePicture] =
    useProfile();

  const [showEditClaimForm, setShowEditClaimForm] = useState(false);
  const [editClaim, setEditClaim] = useState<WarrantyClaim | null>(null);
  const [editClaimCounter, setEditClaimCounter] = useState(0);
  const [showEditCustomerForm, setShowEditCustomerForm] = useState(false);
  const [showEditWarrantyTransferForm, setShowEditWarrantyTransferForm] =
    useState(false);
  const [editCustomer, setEditCustomer] = useState<Customer | null>(null);
  const [editCustomerCounter, setEditCustomerCounter] = useState(0);
  const [editWarrantyTransfer, setEditWarrantyTransfer] =
    useState<WarrantyTransferRequest | null>(null);
  const [editWarrantyTransferCounter, setEditWarrantyTransferCounter] =
    useState(0);

  let currentTabPosition = 0;
  if (matchCustomer) currentTabPosition = 0;
  if (matchClaims) currentTabPosition = 1;
  if (matchTransfer) currentTabPosition = 2;
  if (matchClaimsReport && profile.role === "admin") currentTabPosition = 3;
  if (matchRegistrationsReport && profile.role === "admin")
    currentTabPosition = 4;
  if (matchFRCReport && profile.role === "admin") currentTabPosition = 5;
  if (matchSettings && profile.role === "admin") currentTabPosition = 6;

  return (
    <SessionContext.Consumer>
      {({ session }) => (
        <>
          <Header
            session={session}
            title="Warranty"
            breadcrumbs={[
              {
                title: "Warranty",
                link: `${appConfig.homepage}warranty/`,
              },
            ]}
          />
          <div className={classes.root}>
            <AppBar
              position="static"
              color="default"
              className={classes.tabNavigation}
            >
              <Tabs value={currentTabPosition} aria-label="">
                <Tab
                  label="Warranty Registrations"
                  to={`${appConfig.homepage}warranty/customers/`}
                  component={RouterLink}
                />
                <Tab
                  label="Claims"
                  to={`${appConfig.homepage}warranty/claims/`}
                  component={RouterLink}
                />
                {/* <Tab
                  label="Warranty Transfers"
                  to={`${appConfig.homepage}warranty/warranty-transfers/`}
                  component={RouterLink}
                /> */}
                {profile.role === "admin" && (
                  <Tab
                    label="Claims Report"
                    to={`${appConfig.homepage}warranty/reports/claim/`}
                    component={RouterLink}
                  />
                )}
                {profile.role === "admin" && (
                  <Tab
                    label="Registrations Report"
                    to={`${appConfig.homepage}warranty/reports/registration/`}
                    component={RouterLink}
                  />
                )}
                {profile.role === "admin" && (
                  <Tab
                    label="FRC Report"
                    to={`${appConfig.homepage}warranty/reports/frc/`}
                    component={RouterLink}
                  />
                )}
                {profile.role === "admin" && (
                  <Tab
                    label="Settings"
                    to={`${appConfig.homepage}warranty/settings/`}
                    component={RouterLink}
                  />
                )}
              </Tabs>
            </AppBar>
            <Switch>
              <Route path={`${path}claims/`}>
                <Paper className={classes.toolbar}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setEditClaim(null);
                      setShowEditClaimForm(true);
                    }}
                  >
                    New Warranty Claim
                  </Button>
                </Paper>

                <Route exact path={`${path}claims/:warrantyClaimId/`}>
                  <WarrantyClaimDetailPanel
                    onUpdated={(warrantyClaim) => {
                      setEditClaimCounter(editClaimCounter + 1);
                    }}
                    onDeleted={(warrantyClaim) => {
                      setEditClaimCounter(editClaimCounter + 1);
                    }}
                  />
                </Route>

                <WarrantyClaimCollection
                  key={`warranty-collections-${editClaimCounter}`}
                />
              </Route>

              <Route path={`${path}customers/`}>
                <Paper className={classes.toolbar}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setEditCustomer(null);
                      setShowEditCustomerForm(true);
                    }}
                  >
                    New Warranty Registration
                  </Button>
                </Paper>

                <Route exact path={`${path}customers/:customerId/`}>
                  <CustomerDetailPanel
                    onUpdated={(customer) => {
                      setEditCustomerCounter(editCustomerCounter + 1);
                    }}
                    onDeleted={(customer) => {
                      setEditCustomerCounter(editCustomerCounter + 1);
                    }}
                  />
                </Route>

                <CustomerCollection
                  key={`claim-collections-${editCustomerCounter}`}
                />
              </Route>

              {/* <Route path={`${path}warranty-transfers/`}>
                <Paper className={classes.toolbar}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setEditWarrantyTransfer(null);
                      setShowEditWarrantyTransferForm(true);
                    }}
                  >
                    New Warranty Transfer Request
                  </Button>
                </Paper>

                <Route exact path={`${path}warranty-transfers/:transferId/`}>
                  <WarrantyTransferDetailPanel
                    onUpdated={(customer) => {
                      setEditWarrantyTransferCounter(
                        editWarrantyTransferCounter + 1,
                      );
                    }}
                    onDeleted={(customer) => {
                      setEditWarrantyTransferCounter(
                        editWarrantyTransferCounter + 1,
                      );
                    }}
                  />
                </Route>

                <WarrantyTransferCollection
                  key={`warranty-transfer-collections-${editWarrantyTransferCounter}`}
                />
              </Route> */}

              <Route path={`${path}reports/claim/`}>
                <ClaimsReport />
              </Route>

              <Route path={`${path}reports/registration/`}>
                <RegistrationsReport />
              </Route>

              <Route path={`${path}reports/frc/`}>
                <FRCReport />
              </Route>

              <Route path={`${path}settings/`}>
                <WarrantySettings />
              </Route>
            </Switch>

            <Dialog
              open={showEditClaimForm}
              onClose={() => {
                setShowEditClaimForm(false);
                setEditClaim(null);
              }}
              fullScreen
              TransitionComponent={Transition}
            >
              <>
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <Typography variant="h6" className={classes.dialogTitle}>
                      {editClaim
                        ? `Edit Claim ${editClaim.claim_number}`
                        : "Create New Warranty Claim"}
                    </Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        setShowEditClaimForm(false);
                        setEditClaim(null);
                      }}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <WarrantyClaimForm
                    claim={editClaim}
                    dealerId=""
                    onSave={(claim) => {
                      setShowEditClaimForm(false);
                      setEditClaim(null);
                      setEditClaimCounter(editClaimCounter + 1);
                    }}
                    onCancel={() => {
                      setShowEditClaimForm(false);
                      setEditClaim(null);
                    }}
                  />
                </DialogContent>
              </>
            </Dialog>

            <Dialog
              open={showEditCustomerForm}
              onClose={() => {
                setShowEditCustomerForm(false);
                setEditCustomer(null);
              }}
              fullScreen
              TransitionComponent={Transition}
            >
              <>
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <Typography variant="h6" className={classes.dialogTitle}>
                      {editCustomer
                        ? `Edit ${editCustomer.first_name} ${editCustomer.last_name}`
                        : "Create New Warranty Registration"}
                    </Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        setShowEditCustomerForm(false);
                        setEditCustomer(null);
                      }}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <CustomerForm
                    customer={editCustomer}
                    dealerId=""
                    onSave={(customer) => {
                      setShowEditCustomerForm(false);
                      setEditCustomer(null);
                      setEditCustomerCounter(editCustomerCounter + 1);
                    }}
                    onCancel={() => {
                      setShowEditCustomerForm(false);
                      setEditCustomer(null);
                    }}
                  />
                </DialogContent>
              </>
            </Dialog>

            <Dialog
              open={showEditWarrantyTransferForm}
              onClose={() => {
                setShowEditWarrantyTransferForm(false);
                setEditWarrantyTransfer(null);
              }}
              fullScreen
              TransitionComponent={Transition}
            >
              <>
                <AppBar className={classes.appBar}>
                  <Toolbar>
                    <Typography variant="h6" className={classes.dialogTitle}>
                      {editWarrantyTransfer
                        ? `Edit ${editWarrantyTransfer.first_name} ${editWarrantyTransfer.last_name}`
                        : "Create New Warranty Transfer Request"}
                    </Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        setShowEditWarrantyTransferForm(false);
                        setEditWarrantyTransfer(null);
                      }}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <WarrantyTransferRequestForm
                    transfer={editWarrantyTransfer}
                    dealerId=""
                    onSave={(transfer) => {
                      setShowEditWarrantyTransferForm(false);
                      setEditWarrantyTransfer(null);
                      setEditWarrantyTransferCounter(
                        editWarrantyTransferCounter + 1,
                      );
                    }}
                    onCancel={() => {
                      setShowEditWarrantyTransferForm(false);
                      setEditWarrantyTransfer(null);
                    }}
                  />
                </DialogContent>
              </>
            </Dialog>
          </div>
        </>
      )}
    </SessionContext.Consumer>
  );
}
